import logo from './Logo_horz_forlife.png';
import './App.css';
import React, { useEffect, useRef, useState } from 'react';

function App() {
  const [counter, setCounter] = useState(10);
  const [counting, setCounting] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [finished, setFinished] = useState(false);
  const [items, setItems] = useState('');
  const [winners, setWinners] = useState('1');
  const [result, setResult] = useState([]);
  const [params, setParams] = useState();
  const genRef = useRef();
  const genInt = useRef();

  useEffect(() => {
    const p = new URLSearchParams(window.location.search);
    setParams(p);
  }, [window.location.search])
  
  useEffect(() => {
    if (!generating) return;

    let candidates = items.split("\n").filter(i => !!i);
    let local = [];
    genRef.current = () => {
      if (local.length === parseInt(winners, 10)) {
        clearInterval(genInt.current);
        setGenerating(false);
        setFinished(true);
      } else {
        let rand = Math.floor(Math.random() * candidates.length);
        local.push(candidates[rand]);
        setResult(local);
        candidates.splice(rand, 1);
        setItems(candidates.join("\n"));
      }
    };
    genInt.current = setInterval(genRef.current, 3000);
  }, [generating]);
  

  useEffect(() => {
    if (!counting) return;
    
    let c = 9;
    let countdown = setInterval(() => {
      if (c < 1) {
        setCounting(false);
        setGenerating(true);
      }
      setCounter(c);
      c--;
    }, 1000);
  
    return () => {
      clearInterval(countdown);
    }
  }, [counting]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        
        <h1>{ params ? params.get('title') || 'Cursos Grátis!' : 'Cursos Grátis!' }</h1>
        
        <p>Sorteio</p>
        <div className="container">
          <div className="candidates">
            <h2>Candidatos</h2>

            <textarea 
              value={items}
              rows="11"
              onChange={e => setItems(e.target.value)}></textarea>
          </div>
          <div className="draw">
            <h2>Resultado!</h2>

            {counting ? (
              <div className="counter">{ counter }</div>
            ) : '' }

            { !counting && !generating && !finished ? (
              <React.Fragment>
                <input 
                  className="winners" 
                  value={winners} 
                  onChange={e => setWinners(e.target.value)}
                /><br />
                <button 
                  className="action" 
                  disabled={items.split("\n").length < parseInt(winners, 10)}
                  onClick={e => { setCounter(10); setCounting(true); }}>
                    Sortear!
                </button>
              </React.Fragment>
            ) : '' }
            
            { result.length > 0 ? 
              <ol className="result">
                { result.map(r => (
                  <li key={r}>{ r }</li>
                ))}
              </ol>
            : null }

            { finished ? (
              <button 
                className="reset" 
                onClick={e => { setCounting(false); setGenerating(false); setFinished(false); setItems(''); setResult([]); }}>
                  Reiniciar
              </button>
            ) : null }

          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
